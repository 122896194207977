import React, { useEffect, useRef } from 'react';

const AmazonIVSPlayer = ({ playbackUrl }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        // Cargar el script de Amazon IVS Player
        const script = document.createElement('script');
        script.src = 'https://player.live-video.net/1.24.0/amazon-ivs-player.min.js';
        script.async = true;

        // Función que crea el reproductor después de que el script se carga
        script.onload = () => {
            if (window.IVSPlayer && window.IVSPlayer.isPlayerSupported) {
                const player = window.IVSPlayer.create();
                player.attachHTMLVideoElement(videoRef.current);
                player.load(playbackUrl);
                player.play();
            }
        };

        document.body.appendChild(script);

        // Limpia el script cuando el componente se desmonte
        return () => {
            document.body.removeChild(script);
        };
    }, [playbackUrl]);

    return (
        <video ref={videoRef} playsInline width="100%" controls={true} />
    );
};

export default AmazonIVSPlayer;
