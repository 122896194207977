import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import Loading from '../loading/Loading';
import { addOffer, deleteOffer, notifyUser } from '../../services/OffersServices';
import { toast } from 'react-toastify';
import './ProductContent.scss';
import { logoutAction } from '../../redux/actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';
import { CheckCircle, DashCircle, Facebook, XCircle } from 'react-bootstrap-icons';
import { useAccount } from '../../store/account';
import ActiveNotifications from '../Shared/activeNotifications';

const ProductContent = (props) => {

    const { fetchLot, nextOffer, packing_cost, configAccount, allow_automatic_offers, date_close,
        automaticOffer, sessionProps, id, logout, timestamp, title, id_lot, price_base, bestUserNameOffer,
        status, bestUserIdOffer, participation, auction, offer: offer_, offersLogs, description,
        timestampClose,
        countDownVisible,
        message,
    } = props

    const [loading, setLoading] = useState(false)
    const [manualOffer, setManualOffer] = useState(nextOffer)
    const accountStore = useAccount();
    const [offerType, setOfferType] = useState(1);

    const handleChange = async (event) => {
        const { value } = event.target;
        let valor = parseInt(Math.round(value / 10) * 10);
        if (valor < nextOffer) {
            setManualOffer(nextOffer)
        }
        else {
            setManualOffer(valor)
        }
    }

    const offer = () => {
        let answer = false;
        if (nextOffer > 1000) {
            if (manualOffer >= nextOffer + nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (manualOffer >= nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            const isAutomatic = allow_automatic_offers && offerType === 1
            let title = `¿Desea ofertar ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(manualOffer)}?`
            if (isAutomatic) {
                title = `¿Desea activar la oferta automática hasta ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(manualOffer)}?`
            }
            confirmAlert({
                title,
                message: '',
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            _offer()
                        }
                    }
                ]
            });
        }
        else {
            _offer()
        }
    }

    const _offer = async () => {
        setLoading(true)
        try {
            const automaticOffer = manualOffer > nextOffer ? manualOffer : null
            const response = await addOffer({
                token: sessionProps.account.token,
                id_lot: id,
                offer: (!allow_automatic_offers || offerType === 2) && manualOffer > nextOffer ? manualOffer : nextOffer,
                automaticOffer: allow_automatic_offers && offerType === 1 ? automaticOffer : null
            });
            setLoading(false)
            fetchLot();
            toast("Tu oferta ha sido ingresada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });

            const lot = response.data.data.lot;
            if (configAccount.config?.use_notifications && lot.notify) {
                notifyUser({
                    token: sessionProps?.account?.token,
                    l: lot.id,
                    i: lot.notify,
                })
            }
        } catch (error) {
            setLoading(false)
            if (error.response?.status === 401) {
                logout();
                window.history.push(`/ingresar/${id}/0/lotes`);
            }
            else {
                toast.error("No fue posible ingresar tu oferta, reintentar nuevamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const deleteOfferConfirm = (item) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='col-12 text-center align-items-center'>
                        <XCircle color='red' size={60} fontWeight={'bold'} />
                        <b><h2 className='text-danger'>Cancelar oferta</h2></b>
                        <b><p style={{ fontSize: 16 }} className='mt-4 mb-4'>¿Esta seguro que desea cancelar la oferta?</p></b>
                        <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                            <button className='rounded-pill btn btn-danger col-6' onClick={onClose}>Cancelar</button>
                            <button style={{ fontSize: 18 }} className='rounded-pill btn btn-primary col-6 mt-2'
                                onClick={() => {
                                    _deleteOffer(item)
                                    onClose();
                                }}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    const _deleteOffer = async (item) => {
        setLoading(true);
        try {
            await deleteOffer({
                token: sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            setLoading(false);
            fetchLot();
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            setLoading(false);
            if (error !== undefined && error.response !== undefined && error.response?.status === 401) {
                logout();
                window.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const countDownCancelOffer = ({ item }) => {
        try {
            if (sessionProps.account === null || sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 >= 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => deleteOfferConfirm(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    const getIdLot = (id_lot) => {
        if (id_lot.includes('-')) {
            let part = id_lot.split("-");
            let number = parseInt(part[0], 10);
            return number + "-" + part[1];
        }
        return parseInt(id_lot);
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const _countDown = ({ item }) => {
        if (timestamp === null || item.timestampClose === null || date_close === '2050-01-01 00:00:00') {
            return null;
        }
        //
        let difference = new Date(item.timestampClose * 1000).getTime() - new Date(timestamp * 1000).getTime();
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            if (item.status === Constants.STATUS_IN_PROGRESS) {
                item.timestampClose = null;
                item.countDownVisible = false;
                if (item.bestUserIdOffer !== null) {
                    item.status = Constants.STATUS_PENDING;
                } else {
                    item.status = Constants.STATUS_NOT_AUCTIONED;
                }
            }
            return null;
        }
        //
        if (days === 0 && hours === 0 && minutes === 0 && seconds <= 5 && seconds > 0) {
            if (item.message !== 'Cerrando lote...') {
                item.message = 'Cerrando lote...';
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            Cerrando lote...
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        if (days === 0 && hours === 0 && (minutes < 2 || (minutes === 2 && seconds === 0))) {
            if (item.countDownVisible !== true) {
                item.countDownVisible = true;
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            return (
                <React.Fragment>
                    <div className="timeclock warning">
                        <div className='first-div'>
                            <div className='item'>
                                <span>00</span>
                                <span>Horas</span>
                            </div>
                            <div className='item'>
                                <span>{minutes}</span>
                                <span>Min</span>
                            </div>
                            <div className='item'>
                                <span>{seconds}</span>
                                <span>Seg</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        if ((days > 0 || hours > 0 || minutes >= 2) && (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_PENDING)) {
            if (hours.toString().length === 1) {
                hours = '0' + hours;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            return (
                <React.Fragment>
                    <div className="timeclock normal">
                        <div className='first-div'>
                            {
                                days > 0 ? (
                                    <div className='item'>
                                        <span>{days}</span>
                                        <span>Días</span>
                                    </div>
                                ) : null
                            }
                            <div className='item'>
                                <span>{hours}</span>
                                <span>Horas</span>
                            </div>
                            <div className='item'>
                                <span>{minutes}</span>
                                <span>Min</span>
                            </div>
                            <div className='item'>
                                <span>{seconds}</span>
                                <span>Seg</span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        return null;
    }

    const countDown = ({ item }) => {
        let countDown = _countDown({ item });
        if (countDown !== null) {
            return countDown;
        }
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className="product-details-content">

                <h3 className="text-black mb-2" style={{ fontWeight: 'bold' }}>
                    Lote {window.socket_io_customer === 'araujo_subastas' ? getIdLot(id_lot) : '#' + id_lot} - {title}
                </h3>
                <ul className='list-lote'>
                    <li><span>Base:</span><p className='d-inline ml-3' >{price_base > 0 ?
                        ` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(price_base)}` : ' Sin precio inicial'}</p></li>
                    {
                        configAccount?.config?.use_cost_packing && packing_cost > 0 ? (
                            <li className='mt-1'><span>Costo embalaje:</span><p className='d-inline ml-2'>
                                {`${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(packing_cost)}`}</p></li>
                        ) : null
                    }
                    {
                        window.socket_io_customer !== 'araujo_subastas' && bestUserNameOffer &&
                        <li className='mt-1'><span>Mejor postor:</span><p className='d-inline ml-2' > {bestUserNameOffer}</p></li>
                    }
                    {
                        status === Constants.STATUS_IN_PROGRESS &&
                        <li className='mt-1'><span>Oferta actual:</span><p className='d-inline ml-2' > {` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(offer_)}`}</p></li>
                    }
                    {
                        date_close !== '2050-01-01 00:00:00' ? (
                            <li className='mt-1'><span>Cierre:</span> <p className='d-inline ml-2' >{` ${capitalize(moment(date_close).format('dddd D'))} de 
                                ${capitalize(moment(date_close).format('MMMM'))} - ${moment(date_close).format('HH:mm')}`} hs</p></li>
                        ) : null
                    }
                </ul>

                <div className="row">
                    <div className="col-lg-12 col-md-12" style={{ marginBottom: '1rem' }}>
                        <div dangerouslySetInnerHTML={{ __html: description }} className="description-lots" />
                    </div>
                </div>

                <div className="tab products-details-tab">
                    {
                        countDown({
                            item: {
                                status,
                                timestampClose,
                                countDownVisible,
                                bestUserIdOffer,
                                message,
                            }
                        })
                    }
                </div>


                {
                    auction.auctionModalityOnline ?
                        <>
                            <hr className='mt-4 mb-4' />
                            <div className="buy-checkbox-btn d-flex flex-column flex-md-row mb-4 col-12 justify-content-between align-items-md-center">
                                {
                                    status === Constants.STATUS_IN_PROGRESS ?
                                        <React.Fragment>
                                            {
                                                sessionProps.account === null &&
                                                <Link to={`/ingresar/${id}/0/lotes`}>
                                                    <button className={`btn login-to-offer rounded-pill w-100 f-16 ${window.style_config?.buttons?.showHoverOfferButtons ? 'not-offered_hover' : ''}`}>
                                                        {
                                                            window.socket_io_customer === 'araujo_subastas' ?
                                                                'OFERTAR' : 'INGRESAR PARA OFERTAR'
                                                        }
                                                    </button>
                                                </Link>
                                            }
                                            {
                                                sessionProps.account !== null && sessionProps.account.id !== bestUserIdOffer ?
                                                    participation ?
                                                        <Link to="#">
                                                            <button className={`btn not_offered rounded-pill w-100 f-16 ${manualOffer > nextOffer ? 'losing-animation' : 'losing'}
                                                ${window.style_config?.buttons?.showHoverOfferButtons ? 'losing_hover' : ''}`}
                                                                onClick={() => offer(props)}>
                                                                OFERTAR {` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(
                                                                    manualOffer !== undefined && manualOffer > props.manualOffer ? manualOffer : props.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#">
                                                            <button className={`btn not_offered rounded-pill w-100 f-16`}
                                                                onClick={() => offer(props)}>
                                                                OFERTAR {` ${auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(
                                                                    manualOffer !== undefined && manualOffer > props.manualOffer ? manualOffer : props.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                    : null
                                            }
                                            {
                                                sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer &&
                                                <Link to="#">
                                                    <button className="btn winning rounded-pill text-white w-100 f-16" disabled={true}>
                                                        <i className="fas fa-star"></i> MEJOR OFERTA
                                                    </button>
                                                </Link>
                                            }

                                            {
                                                sessionProps.account !== null && !allow_automatic_offers && sessionProps.account.id !== bestUserIdOffer &&
                                                <div className="item text-center" style={{ marginTop: '5px' }}>
                                                    <div className="product-add-to-cart">
                                                        <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                            <input type="number" min={nextOffer} step="10" placeholder="Ingresar monto manual" style={{ width: '100%', textAlign: 'center' }}
                                                                onChange={(event) => handleChange(event)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                sessionProps.account !== null && allow_automatic_offers && (automaticOffer || 0) < nextOffer ? (
                                                    <div className="item text-center" style={{ marginTop: '5px' }}>
                                                        <div className="product-add-to-cart">
                                                            {
                                                                window.socket_io_customer === 'araujo_subastas' ? (
                                                                    <>
                                                                        <div className="input-counter d-flex" style={{ width: '100%', maxWidth: '100%' }} >
                                                                            <input type="number" min={nextOffer} step="10" placeholder="Ingresar oferta" style={{ width: '100%', textAlign: 'center', borderRadius: '50rem' }}
                                                                                onChange={(event) => handleChange(event)} />
                                                                        </div>
                                                                        <div className="input-counter d-flex offer-type" style={{ width: '100%', maxWidth: '100%' }} >
                                                                            <button className={`no-border-right ${offerType === 1 ? 'active' : ''}`}
                                                                                onClick={() => setOfferType(1)}
                                                                            >
                                                                                Automática
                                                                            </button>
                                                                            <button className={`no-border-left ${offerType === 2 ? 'active' : ''}`}
                                                                                onClick={() => setOfferType(2)}
                                                                            >
                                                                                Manual
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                                        <input type="number" min={nextOffer} step="10" placeholder="Ingresar oferta automática" style={{ width: '100%', textAlign: 'center', borderRadius: '50rem' }}
                                                                            onChange={(event) => handleChange(event)} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ) : sessionProps.account !== null && allow_automatic_offers && (automaticOffer || 0) > 0 ? (
                                                    <div style={{
                                                        marginTop: '0.5rem',
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        <i className="fas fa-star"></i> Automática hasta {auction.moneySymbol}{automaticOffer}
                                                    </div>
                                                )
                                                    : null
                                            }
                                            {
                                                countDownCancelOffer({ item: props })
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                status === Constants.STATUS_PENDING || status === Constants.STATUS_DELIVERED ?
                                                    <React.Fragment>
                                                        {
                                                            sessionProps.account !== null && sessionProps.account.id === bestUserIdOffer ?
                                                                <Link to="#">
                                                                    <button className="p-2 f-16 rounded-pill font-weight-bold w-100 mt-2 secondary-background  winning" disabled={true}>
                                                                        <i className="fas fa-star"></i>&nbsp;&nbsp;Subastado&nbsp;{auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(offer_)}
                                                                    </button>
                                                                </Link>
                                                                :
                                                                <Link to="#" className="noHover">
                                                                    <button disabled={true} className={`p-2 rounded-pill w-100  white-background f-16 text-center auctioned`} >
                                                                        <CheckCircle size={20} />&nbsp;&nbsp; Subastado {auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(offer_)}
                                                                    </button>
                                                                </Link>
                                                        }
                                                    </React.Fragment>
                                                    : null
                                            }
                                            {
                                                status === Constants.STATUS_NOT_AUCTIONED &&
                                                <Link to="#" className="noHover">
                                                    <button className="btn w-100 rounded-pill btn-danger f-16 not_auctioned" disabled={true}>
                                                        <DashCircle color={'white'} size={20} />&nbsp;&nbsp; NO subastado
                                                    </button>
                                                </Link>
                                            }
                                        </React.Fragment>
                                }
                            </div>
                        </> : null
                }

                {
                    auction.auctionModalityOnline && window.socket_io_customer !== 'araujo_subastas' && accountStore?.config?.include_page_faq ? (
                        <div className="item mt-2">
                            <Link to="/ayuda">
                                <span className='text-black font-weight-bold' style={{ cursor: "pointer", textDecoration: 'underline', fontSize: '15px' }}>
                                    Revisá las preguntas frecuentes
                                </span>
                            </Link>
                        </div>
                    ) : null
                }

                {
                    auction.auctionModalityOnline ?
                        <ActiveNotifications hideHrBottom />
                        : null
                }

                <hr className='mb-4' />

                <div className='mt-4'>
                    {
                        auction.auctionModalityOnline && bestUserNameOffer && offersLogs ? (
                            <div className="last-offers">
                                <span className='text-black font-weight-bold'>Últimas ofertas:</span>
                                {
                                    offersLogs.map((offer, index) => (
                                        <p>{offer.name}: <b>{auction.moneySymbol} {offer.offer}</b></p>
                                    ))
                                }
                            </div>
                        ) : null
                    }
                </div>

            </div>
        </React.Fragment >
    );
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContent);