import { Actions } from "./Actions";
import { Constants } from "../../Constants";
var CryptoJS = require("crypto-js");

export const userSetStorageAction = (data) => {
    return async dispatch => {
        try {
            setLocalStorage(data);
            dispatch(userSetStorageActionSuccess({
                account: data
            }));
            window.location.reload();
        } catch (error) {
            
        }
    }
}

export const userSetStorageActionSuccess = (data) => ({
    type: Actions.USER_LOGIN_SET_STORAGE,
    data: data
})

export const userIsLoggedAction = () => {
    return dispatch => {
        const result = getLocalStorage();
        if (result) {
            dispatch(userSetStorageActionSuccess({
                account: result
            }));
        } else {
            dispatch(userSetStorageActionSuccess({
                account: null
            }));
        }
    }
}

export const logoutAction = () => {
    return dispatch => {
        removeLocalStorage();
        dispatch(userSetStorageActionSuccess({
            account: null
        }));
    }
}

export const setPhoneInLocalStorage = (data) => {
    return dispatch => {
        const result = getLocalStorage();
        if (result) {
            result.phone = data.phone
            setLocalStorage(result);
            dispatch(userSetStorageActionSuccess({
                account: result
            }));
        }
    }
}

const setLocalStorage = (data) => {
    localStorage.setItem(Constants.HASH_USER_SESSION,
        CryptoJS.AES.encrypt(JSON.stringify(data), Constants.KEY_USER_SESSION));
}

const removeLocalStorage = () => {
    localStorage.removeItem(Constants.HASH_USER_SESSION);
}

const getLocalStorage = () => {
    let result = localStorage.getItem(Constants.HASH_USER_SESSION);
    if (result) {
        let bytes = CryptoJS.AES.decrypt(result, Constants.KEY_USER_SESSION);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(plaintext);
    }
    return null;
} 