import React, { Fragment } from 'react';
import Footer from './components/Layout/footer/index';
import Menu from './components/Layout/menu';

const GlobalContainer = (props) => {
  return (
    <Fragment>
      <Menu {...props} />
      <div>{props.children}</div>
      <Footer />
    </Fragment>
  );
};

export default GlobalContainer;
